import React from 'react';

export default function Links() {
    return (
        <div className="links" style={{ paddingLeft: '0.3%', paddingTop: '0.5%' }}>
            <a target="_blank" rel="noreferrer" href="https://ai4bharat.org/">
                <img style={{ width: '100px' }} src="https://i.imgur.com/pVT5Mjp.png" alt="AI4B Logo" />
            </a>
        </div>
    );
}
