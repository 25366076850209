import styled from 'styled-components';
// import languages from '../libs/languages';
import { t, Translate } from 'react-i18nify';
import React, { useState, useEffect, useCallback } from 'react';
// import { getExt, download } from '../utils';
// import { download } from '../utils';
// import { file2sub, sub2vtt, sub2srt, sub2txt, url2sub, vtt2url } from '../libs/readSub';
// import { sub2srt } from '../libs/readSub';
// import sub2ass from '../libs/readSub/sub2ass';
// import googleTranslate from '../libs/googleTranslate';
// import englishKeywordsTranslate from '../libs/englishKeywordsTranslate';
import FFmpeg from '@ffmpeg/ffmpeg';
// import SimpleFS from '@forlagshuset/simple-fs';
import HamburgerMenu from 'react-hamburger-menu';
// import * as zlib from 'browserify-zlib';
// import untar from 'js-untar';
// import { Buffer } from 'buffer';
import '../utils/ToolNavigation.css';
// import BottomLinks from './BottomLinks';
// import Modal from './Modal';
import DT from 'duration-time-conversion';

const Style = styled.div`
    border-left: 1px solid white;

    .tool-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 10px 2px 0;
        margin-bottom: 5px;

        .icon {
            cursor: pointer;
        }
    }

    .import {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 48%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #3f51b5;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }

        .file {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .seconday-options {
        transform: scaleY(1) !important;
        transition: all 0.25s 0.4s;
        transform-origin: top;

        p {
            font-size: 18px;
        }
    }

    .hide-secondary-options {
        transform: scaleY(0) !important;
        transition: all 0.25s;
        transform-origin: top;
        height: 0;
    }

    .hide-secondary-options * > {
        opacity: 0;
        transition: all 0.1s;
    }

    .select-translation-api-container {
        border-bottom: 1px solid rgb(255 255 255 / 20%);
        display: flex;
        flex-direction: column;
        // justify-content: center;
        padding-left: 10px;

        .select-heading {
            margin-top: -0.5px;
        }

        select {
            width: 95%;
            outline: none;
            height: 35px;
            border-radius: 3px;
            margin-bottom: 10px;
            margin-top: -10px;
        }
    }

    .burn {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .export {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #009688;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .operate {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 48%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #009688;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .configuration {
        transform: scaleY(1) !important;
        transition: all 0.25s 0.4s;
        transform-origin: top;

        &-heading {
            opacity: 1;
            padding-left: 10px;
            margin-top: -0.5px;
        }

        &-options {
            opacity: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            border-bottom: 1px solid rgb(255 255 255 / 20%);
            // align-items: center;
            margin-top: -10px;
            padding-left: 10px;

            .btn {
                position: relative;
                opacity: 0.85;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                width: 95%;
                border-radius: 3px;
                color: #fff;
                cursor: pointer;
                font-size: 13px;
                background-color: #009688;
                transition: all 0.2s ease 0s;
                margin-bottom: 10px;
                letter-spacing: 1.3px;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .hide-config {
        transform: scaleY(0) !important;
        transition: all 0.25s;
        transform-origin: top;
        height: 0;
    }
    .hide-config .configuration-heading {
        opacity: 0;
        transition: all 0.1s;
    }

    .hide-config .configuration-options {
        opacity: 0;
        transition: all 0.1s;
    }

    .translate {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        select {
            width: 65%;
            outline: none;
            padding: 0 5px;
            border-radius: 3px;
        }

        .btn {
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 33%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }
    .youtube-link {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        p {
            font-size: 18px;
            margin-bottom: 0;
        }

        .youtube-textarea {
            width: 65%;
            outline: none;
            padding: 0 5px;
            border-radius: 3px;
        }

        .btn {
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .renew-token {
        display: flex;
        padding: 10px;
        padding-top: 0;
        border-bottom: 1px solid rgb(255 255 255 / 20%);
        justify-content: space-between;
        flex-direction: column;

        p {
            font-size: 18px;
        }

        .btn {
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }

        &-input {
            height: 35px !important;
            padding: 0 5px;
            border-radius: 3px;
            oultine: none;
        }
    }

    .hotkey {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        span {
            width: 49%;
            font-size: 13px;
            padding: 5px 0;
            border-radius: 3px;
            text-align: center;
            color: rgb(255 255 255 / 75%);
            background-color: rgb(255 255 255 / 20%);
        }
    }

    .bottom {
        padding: 10px;
        a {
            display: flex;
            flex-direction: column;
            border: 1px solid rgb(255 255 255 / 30%);
            text-decoration: none;

            .title {
                color: #ffeb3b;
                padding: 5px 10px;
                animation: animation 3s infinite;
                border-bottom: 1px solid rgb(255 255 255 / 30%);
            }

            @keyframes animation {
                50% {
                    color: #00bcd4;
                }
            }

            img {
                max-width: 100%;
            }
        }
    }

    .progress {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 9;
        height: 2px;
        background-color: rgb(0 0 0 / 50%);

        span {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0;
            height: 100%;
            background-color: #ff9800;
            transition: all 0.2s ease 0s;
        }
    }
`;

FFmpeg.createFFmpeg({ log: true }).load();
// const fs = new SimpleFS.FileSystem();

export default function Header({
    player,
    waveform,
    newSub,
    undoSubs,
    clearSubs,
    language,
    subtitle,
    setLoading,
    formatSub,
    setSubtitle,
    setProcessing,
    notify,
    subtitleEnglish,
    setSubtitleEnglish,
    clearSubsEnglish,
    setSubtitleOriginal,
    clearedSubs,
    setClearedSubs,
    configuration,
    setConfiguration,
    enableConfiguration,
    setEnableConfiguration,
    isSetConfiguration,
    setIsSetConfiguration,
    translationApi,
    setTranslationApi,
    setSentence,
    setVideoSrc,
    setIsOpen,
    videoFile,
    setVideoFile,
    downloadSub,
    handleFetchVideo,
    tokenToUpdate,
    setTokenToUpdate,
    isOpenTokenModal,
    setIsOpenTokenModal,
    renewedToken,
    setRenewedToken,
    playbackSpeed,
    setPlaybackSpeed,
    playbackSelected,
    setPlaybackSelected,
    videoSrc,
    addSub,
    findIndex,
}) {
    // const [translate, setTranslate] = useState('en');
    // eslint-disable-next-line
    // const [youtubeURL, setYoutubeURL] = useState('');
    // const translate = 'en';

    // const [oldToken, setOldToken] = useState('');
    const [toolOpen, setToolOpen] = useState(true);
    const [isSetVideo, setIsSetVideo] = useState(false);

    const clearSubsHandler = () => {
        window.localStorage.setItem('subsBeforeClear', JSON.stringify(subtitle));

        let tempSubs = subtitle.slice(0);
        tempSubs.map((sub) => {
            sub.text = '';
            sub.text2 = '';
            return true;
        });

        setSubtitleOriginal(tempSubs);
        setClearedSubs(true);
    };

    useEffect(() => {
        if (!localStorage.getItem('videoSrc')) {
            setVideoFile(null);
        } else if (player && waveform && localStorage.getItem('videoSrc')) {
            // setVideoFile(localStorage.getItem('videoSrc'));
            waveform.decoder.destroy();
            waveform.drawer.update();
            // waveform.seek(0);
            player.currentTime = 0;
            player.url = localStorage.getItem('videoSrc');
        }
    }, [player, waveform, setVideoFile]);

    // const decodeAudioData = useCallback(
    //     async (file) => {
    //         try {
    //             const { createFFmpeg, fetchFile } = FFmpeg;
    //             const ffmpeg = createFFmpeg({ log: true });
    //             ffmpeg.setProgress(({ ratio }) => setProcessing(ratio * 100));
    //             setLoading(t('LOADING_FFMPEG'));
    //             await ffmpeg.load();
    //             ffmpeg.FS('writeFile', file.name, await fetchFile(file));
    //             setLoading('');
    //             notify({
    //                 message: t('DECODE_START'),
    //                 level: 'info',
    //             });
    //             const output = `${Date.now()}.mp3`;
    //             await ffmpeg.run('-i', file.name, '-ac', '1', '-ar', '8000', output);
    //             const uint8 = ffmpeg.FS('readFile', output);
    //             // download(URL.createObjectURL(new Blob([uint8])), `${output}`);
    //             await waveform.decoder.decodeAudioData(uint8);
    //             waveform.drawer.update();
    //             setProcessing(0);
    //             ffmpeg.setProgress(() => null);
    //             notify({
    //                 message: t('DECODE_SUCCESS'),
    //                 level: 'success',
    //             });
    //         } catch (error) {
    //             setLoading('');
    //             setProcessing(0);
    //             notify({
    //                 message: t('DECODE_ERROR'),
    //                 level: 'error',
    //             });
    //         }
    //     },
    //     [waveform, notify, setProcessing, setLoading],
    // );

    // const burnSubtitles = useCallback(async () => {
    //     try {
    //         const { createFFmpeg, fetchFile } = FFmpeg;
    //         const ffmpeg = createFFmpeg({ log: true });
    //         ffmpeg.setProgress(({ ratio }) => setProcessing(ratio * 100));
    //         setLoading(t('LOADING_FFMPEG'));
    //         await ffmpeg.load();
    //         setLoading(t('LOADING_FONT'));

    //         await fs.mkdir('/fonts');
    //         const fontExist = await fs.exists('/fonts/Microsoft-YaHei.ttf');
    //         if (fontExist) {
    //             const fontBlob = await fs.readFile('/fonts/Microsoft-YaHei.ttf');
    //             ffmpeg.FS('writeFile', `tmp/Microsoft-YaHei.ttf`, await fetchFile(fontBlob));
    //         } else {
    //             const fontUrl = 'https://cdn.jsdelivr.net/gh/zhw2590582/SubPlayer/docs/Microsoft-YaHei.ttf';
    //             const fontBlob = await fetch(fontUrl).then((res) => res.blob());
    //             await fs.writeFile('/fonts/Microsoft-YaHei.ttf', fontBlob);
    //             ffmpeg.FS('writeFile', `tmp/Microsoft-YaHei.ttf`, await fetchFile(fontBlob));
    //         }
    //         setLoading(t('LOADING_VIDEO'));
    //         ffmpeg.FS(
    //             'writeFile',
    //             videoFile ? videoFile.name : 'sample.mp4',
    //             await fetchFile(videoFile || 'sample.mp4'),
    //         );
    //         setLoading(t('LOADING_SUB'));
    //         const subtitleFile = new File([new Blob([sub2ass(subtitle)])], 'subtitle.ass');
    //         ffmpeg.FS('writeFile', subtitleFile.name, await fetchFile(subtitleFile));
    //         setLoading('');
    //         notify({
    //             message: t('BURN_START'),
    //             level: 'info',
    //         });
    //         const output = `${Date.now()}.mp4`;
    //         await ffmpeg.run(
    //             '-i',
    //             videoFile ? videoFile.name : 'sample.mp4',
    //             '-vf',
    //             `ass=${subtitleFile.name}:fontsdir=/tmp`,
    //             '-preset',
    //             videoFile ? 'fast' : 'ultrafast',
    //             output,
    //         );
    //         const uint8 = ffmpeg.FS('readFile', output);
    //         download(URL.createObjectURL(new Blob([uint8])), `${output}`);
    //         setProcessing(0);
    //         ffmpeg.setProgress(() => null);
    //         notify({
    //             message: t('BURN_SUCCESS'),
    //             level: 'success',
    //         });
    //     } catch (error) {
    //         setLoading('');
    //         setProcessing(0);
    //         notify({
    //             message: t('BURN_ERROR'),
    //             level: 'error',
    //         });
    //     }
    // }, [notify, setProcessing, setLoading, videoFile, subtitle]);

    // const onVideoChange = useCallback(
    //     (event) => {
    //         const file = event.target.files[0];
    //         if (file) {
    //             const ext = getExt(file.name);
    //             const canPlayType = player.canPlayType(file.type);
    //             if (canPlayType === 'maybe' || canPlayType === 'probably') {
    //                 setVideoFile(file);
    //                 decodeAudioData(file);
    //                 const url = URL.createObjectURL(new Blob([file]));
    //                 waveform.decoder.destroy();
    //                 waveform.drawer.update();
    //                 // waveform.seek(0);
    //                 player.currentTime = 0;
    //                 clearSubs();
    //                 setSubtitle([
    //                     newSub({
    //                         start: '00:00:00.000',
    //                         end: '00:00:01.000',
    //                         text: t('SUB_TEXT'),
    //                     }),
    //                 ]);
    //                 player.src = url;
    //             } else {
    //                 notify({
    //                     message: `${t('VIDEO_EXT_ERR')}: ${file.type || ext}`,
    //                     level: 'error',
    //                 });
    //             }
    //         }

    //         localStorage.setItem('isVideoPresent', true);
    //         setIsSetVideo(true);
    //     },
    //     [newSub, notify, player, setSubtitle, waveform, clearSubs, decodeAudioData, setIsSetVideo],
    // );
    // const onYouTubeChange = useCallback(
    //     (event) => {
    //         if (youtubeURL.length > 0) {
    //             setLoading(t('LOADING'));

    //             fetch(
    //                 `http://13.90.168.58:8000/get_youtube_video_link_with_captions?url=${youtubeURL}&lang=${translate}`,
    //                 {
    //                     method: 'POST',
    //                 },
    //             )
    //                 .then((resp) => {
    //                     return resp.json();
    //                 })
    //                 .then((resp) => {
    //                     const url = resp.video;
    //                     const audio = resp.audio;
    //                     player.src = url;

    //                     localStorage.setItem('videoSrc', resp.video);
    //                     localStorage.setItem('audioSrc', resp.audio);

    //                     if (resp.subtitles) {
    //                         const sub = resp.subtitles;

    //                         fetch(sub)
    //                             .then((subtext) => {
    //                                 return subtext.text();
    //                             })
    //                             .then((subtext) => {
    //                                 console.log(subtext);
    //                                 player.currentTime = 0;
    //                                 clearSubs();
    //                                 const suburl = vtt2url(subtext);
    //                                 url2sub(suburl).then((urlsub) => {
    //                                     setSubtitle(urlsub);
    //                                     localStorage.setItem('subtitle', JSON.stringify(urlsub));
    //                                 });
    //                             })
    //                             .catch((err) => {
    //                                 console.log(err);
    //                             });
    //                     } else {
    //                         const data = {
    //                             audio_url: audio,
    //                             vad_level: 2,
    //                             chunk_size: 10,
    //                             language: 'en',
    //                         };

    //                         fetch('http://164.52.212.33:5000/transcribe_audio', {
    //                             method: 'POST',
    //                             headers: { 'Content-Type': 'application/json' },
    //                             body: JSON.stringify(data),
    //                         })
    //                             .then((resp) => {
    //                                 return resp.json();
    //                             })
    //                             .then((resp) => {
    //                                 console.log(resp.output);
    //                                 player.currentTime = 0;
    //                                 clearSubs();
    //                                 const suburl = vtt2url(resp.output);
    //                                 url2sub(suburl).then((urlsub) => {
    //                                     setSubtitle(urlsub);
    //                                     localStorage.setItem('subtitle', JSON.stringify(urlsub));
    //                                 });
    //                             })
    //                             .catch((err) => {
    //                                 console.log(err);
    //                             });
    //                     }
    //                 });
    //             fetch(
    //                 `https://youtube-dl-utils-api.herokuapp.com/get_youtube_video_link_with_captions?url=${youtubeURL}&lang=en`,
    //                 {
    //                     method: 'POST',
    //                 },
    //             )
    //                 .then((resp) => {
    //                     return resp.json();
    //                 })
    //                 .then((resp) => {
    //                     //const url = resp.video;
    //                     const audio = resp.audio;

    //                     if (resp.subtitles) {
    //                         const sub = resp.subtitles;
    //                         fetch(sub)
    //                             .then((subtext) => {
    //                                 return subtext.text();
    //                             })
    //                             .then((subtext) => {
    //                                 clearSubsEnglish();
    //                                 const suburl = vtt2url(subtext);
    //                                 url2sub(suburl).then((urlsub) => {
    //                                     setSubtitleEnglish(urlsub);
    //                                     localStorage.setItem('subtitleEnglish', JSON.stringify(urlsub));
    //                                 });

    //                                 setLoading('');
    //                             })
    //                             .catch((err) => {
    //                                 console.log(err);
    //                             });
    //                     } else {
    //                         const data = {
    //                             audio_url: audio,
    //                             vad_level: 2,
    //                             chunk_size: 10,
    //                             language: 'en',
    //                         };

    //                         fetch('http://164.52.212.33:5000/transcribe_audio', {
    //                             method: 'POST',
    //                             headers: { 'Content-Type': 'application/json' },
    //                             body: JSON.stringify(data),
    //                         })
    //                             .then((resp) => {
    //                                 return resp.json();
    //                             })
    //                             .then((resp) => {
    //                                 console.log(resp.output);
    //                                 player.currentTime = 0;
    //                                 clearSubs();
    //                                 const suburl = vtt2url(resp.output);
    //                                 url2sub(suburl).then((urlsub) => {
    //                                     setSubtitleEnglish(urlsub);
    //                                     localStorage.setItem('subtitleEnglish', JSON.stringify(urlsub));
    //                                 });
    //                             })
    //                             .then(() => setLoading(''))
    //                             .catch((err) => {
    //                                 console.log(err);
    //                             });
    //                     }
    //                 });
    //         }

    //         localStorage.setItem('isVideoPresent', true);
    //         setIsSetVideo(true);
    //     },
    //     [
    //         setSubtitleEnglish,
    //         clearSubs,
    //         youtubeURL,
    //         translate,
    //         clearSubsEnglish,
    //         player,
    //         setSubtitle,
    //         setLoading,
    //         setIsSetVideo,
    //     ],
    // );

    // const handleChange = (e) => {
    //     setYoutubeURL(e.target.value);
    // };

    // const onSubtitleChange = useCallback(
    //     (event) => {
    //         const file = event.target.files[0];
    //         if (file) {
    //             const ext = getExt(file.name);
    //             if (['ass', 'vtt', 'srt', 'json'].includes(ext)) {
    //                 file2sub(file)
    //                     .then((res) => {
    //                         clearSubs();
    //                         setSubtitle(res);
    //                     })
    //                     .catch((err) => {
    //                         notify({
    //                             message: err.message,
    //                             level: 'error',
    //                         });
    //                     });
    //             } else {
    //                 notify({
    //                     message: `${t('SUB_EXT_ERR')}: ${ext}`,
    //                     level: 'error',
    //                 });
    //             }
    //         }
    //     },
    //     [notify, setSubtitle, clearSubs],
    // );

    // const onInputClick = useCallback((event) => {
    //     event.target.value = '';
    // }, []);

    // const wordByWord = () => {
    //     let sentence = '';
    //     let colouredSentence = '';
    //     return subtitle
    //         .map((item, index) => {
    //             if (
    //                 subtitle[index - 1] &&
    //                 subtitle[index - 1].text &&
    //                 subtitle[index - 1].text.length === 1 &&
    //                 item.text.length !== 1
    //             ) {
    //                 console.log(subtitle[index - 1]);
    //                 sentence = sentence + ' ';
    //             }
    //             colouredSentence = sentence + `<b>${item.text}</b>`;
    //             sentence += item.text;
    //             if (item.text.length !== 1) {
    //                 sentence += ' ';
    //             }
    //             return `${index + 1}\n${item.start.replace('.', ',')} --> ${item.end.replace(
    //                 '.',
    //                 ',',
    //             )}\n${colouredSentence}`;
    //         })
    //         .join('\n\n');
    // };

    // const downloadSub = useCallback(
    //     (type) => {
    //         let wordByWordSubtitle = wordByWord();
    //         let text = sub2srt(subtitle);

    //         const url = URL.createObjectURL(new Blob([wordByWordSubtitle]));
    //         // setSubtitle(url);
    //         localStorage.setItem('subtitleUrl', url);

    //         console.log(wordByWordSubtitle);

    //         const body = JSON.parse(localStorage.getItem('assignments'));
    //         body.status = 'COMPLETED';
    //         body.output = {
    //             data: {
    //                 format: 'srt',
    //                 transcript: wordByWordSubtitle,
    //                 originalTranscript: text,
    //             },
    //         };
    //         console.log(body);
    //         fetch('https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignments', {
    //             method: 'PUT',
    //             headers: {
    //                 'karya-id-token': localStorage.getItem('karya-token'),
    //             },
    //             body: JSON.stringify([body]),
    //         })
    //             .then((resp) => {
    //                 console.log(resp);
    //                 clearSubs();
    //                 setSentence(null);
    //                 localStorage.removeItem('sentence');
    //                 return resp.text();
    //             })
    //             .then((resp) => {
    //                 console.log(resp);
    //                 // handleFetchVideo();
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //         // console.log(wordByWordSubtitle, text);
    //     },
    //     // eslint-disable-next-line
    //     [subtitle],
    // );

    // const downloadSubReference = useCallback(
    //     (type) => {
    //         let text = '';
    //         const name = `${Date.now()}.${type}`;
    //         switch (type) {
    //             case 'vtt':
    //                 text = sub2vtt(subtitleEnglish);
    //                 break;
    //             case 'srt':
    //                 text = sub2srt(subtitleEnglish);
    //                 break;
    //             case 'ass':
    //                 text = sub2ass(subtitleEnglish);
    //                 break;
    //             case 'txt':
    //                 text = sub2txt(subtitleEnglish);
    //                 break;
    //             case 'json':
    //                 text = JSON.stringify(subtitleEnglish);
    //                 break;
    //             default:
    //                 break;
    //         }
    //         const url = URL.createObjectURL(new Blob([text]));
    //         download(url, name);
    //     },
    //     [subtitleEnglish],
    // );

    // const handleFetchVideo = async () => {
    //     console.log('Clicked');

    //     if (localStorage.getItem('karya-token') !== null) {
    //         const url = new URL('https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignments');

    //         url.searchParams.append('type', 'new');
    //         url.searchParams.append('from', '1970-01-01T00:00:00Z');

    //         // console.log(url.href);

    //         try {
    //             const response = await fetch(url.href, {
    //                 headers: {
    //                     'karya-id-token': localStorage.getItem('karya-token'),
    //                 },
    //             });

    //             const data = await response.json();

    //             const submitObj = data.assignments[0];

    //             localStorage.setItem('assignments', JSON.stringify(submitObj));

    //             const responseObj = {
    //                 id: data.assignments[0].id,
    //                 sentence: data.microtasks[0].input.data.sentence,
    //                 videoFile: data.microtasks[0].input.files.recording,
    //                 task_id: data.microtasks[0].task_id,
    //             };

    //             localStorage.setItem('fetchVideoObject', JSON.stringify(responseObj));
    //             localStorage.setItem('sentence', responseObj.sentence);
    //             setSentence(localStorage.getItem('sentence'));

    //             fetch(`https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignment/${responseObj.id}/input_file`, {
    //                 headers: {
    //                     'karya-id-token': localStorage.getItem('karya-token'),
    //                 },
    //             })
    //                 .then((resp) => {
    //                     return resp.blob();
    //                 })
    //                 .then((response) => {
    //                     response.arrayBuffer().then((compressed) => {
    //                         const uncompressed = zlib.gunzipSync(Buffer.from(compressed));
    //                         // console.log(uncompressed);
    //                         untar(uncompressed.buffer).then((files) => {
    //                             const file = files[0].buffer;
    //                             console.log(file);
    //                             setVideoFile(file);
    //                             decodeAudioData(file);
    //                             const url = URL.createObjectURL(new Blob([file]));
    //                             localStorage.setItem('videoSrc', url);
    //                             setVideoSrc(url);
    //                             waveform.decoder.destroy();
    //                             waveform.drawer.update();
    //                             // waveform.seek(0);
    //                             player.currentTime = 0;
    //                             clearSubs();
    //                             setSubtitle([
    //                                 newSub({
    //                                     start: '00:00:00.000',
    //                                     end: '00:00:01.000',
    //                                     text: t(`${responseObj.sentence}`),
    //                                 }),
    //                             ]);
    //                             player.src = url;
    //                         });
    //                     });
    //                 });

    //             console.log(responseObj);
    //         } catch (error) {
    //             // localStorage.removeItem('karya-token');
    //             alert(`An Error Occured :
    //             ${error}
    //             `);
    //         }
    //     } else {
    //         const token = prompt('Please Enter a Token to Access Video');

    //         if (token === null) return alert('No token submitted');

    //         localStorage.setItem('karya-token', token);

    //         handleFetchVideo();
    //     }
    // };

    // const handleRenewToken = async () => {
    //     // console.log('clicked');

    //     try {
    //         // console.log(localStorage.getItem('karya-token'));

    //         if (oldToken === '') {
    //             return notify({
    //                 message: 'No token present. Please enter token in the Renew ID-Token text field',
    //                 level: 'error',
    //             });
    //         } else {
    //             const response = await fetch('https://ai4bkaryabox001.eastus.cloudapp.azure.com/renew_id_token', {
    //                 headers: {
    //                     'karya-id-token': oldToken,
    //                 },
    //             });

    //             const data = await response.json();
    //             if (data === undefined || data === null || data.title === 'Authentication failed') {
    //                 return notify({
    //                     message: 'Error : Wrong Token Present Please Enter New Token',
    //                     level: 'error',
    //                 });
    //             }

    //             console.log(data);
    //             localStorage.setItem('karya-token', data.id_token);
    //             console.log('clicked');
    //             setRenewedToken(data.id_token);
    //             setIsOpenTokenModal(true);
    //         }
    //     } catch (error) {
    //         alert('Error : ', error);
    //         console.log(error);
    //     }
    // };

    // const handleUpdateToken = () => {
    //     if (tokenToUpdate === '') {
    //         return notify({
    //             message: `Please enter a token in the Update-ID-Token text field`,
    //             level: 'error',
    //         });
    //     }

    //     localStorage.setItem('karya-token', tokenToUpdate);
    //     return notify({
    //         message: 'Token Successfully Updated',
    //         level: 'success',
    //     });
    // };

    useEffect(() => {
        if (isSetVideo === false) {
            if (window.localStorage.getItem('isVideoPresent') === 'true') {
                // console.log('here inside loop');
                setIsSetVideo(!isSetVideo);
            }
        }
    }, [setIsSetVideo, isSetVideo]);

    useEffect(() => {
        if (localStorage.getItem('playbackSpeed')) {
            setPlaybackSpeed(parseFloat(localStorage.getItem('playbackSpeed')));
        }
    }, [setPlaybackSpeed]);

    // const handlePlaybackOpen = () => {
    //     setPlaybackSelected(true);
    // };
    // const handlePlaybackClose = () => {
    //     setPlaybackSelected(false);
    // };
    const handleClick = () => {
        setPlaybackSelected(!playbackSelected);
    };
    const handlePlaybackSelect = (e) => {
        setPlaybackSpeed(parseFloat(e.target.value));
        setPlaybackSelected(false);
        localStorage.setItem('playbackSpeed', parseFloat(e.target.value));
    };

    const handleInvalid = () => {
        if (videoSrc) {
            let confirmation = window.confirm(
                'Are you sure you want to mark this video as invalid? Confirming will result in a new assignment being fetched!',
            );
            if (confirmation) {
                const body = JSON.parse(localStorage.getItem('assignments'));
                body.status = 'COMPLETED';
                body.output = {
                    data: {
                        format: 'srt',
                        transcript: '',
                        originalTranscript: '',
                        valid: false,
                    },
                };
                // console.log(body);
                fetch('https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignments', {
                    method: 'PUT',
                    headers: {
                        'karya-id-token': localStorage.getItem('karya-token'),
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify([body]),
                })
                    .then((resp) => {
                        // console.log(resp);
                        clearSubs();
                        setSentence(null);
                        localStorage.removeItem('sentence');
                        return resp.text();
                    })
                    .then((resp) => {
                        // console.log(resp);
                        handleFetchVideo();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        // console.log(wordByWordSubtitle, text);
    };

    const createTextboxHandler = useCallback(() => {
        if (player) {
            player.pause();
            console.log(player.currentTime);

            const index = findIndex(subtitle, player.currentTime) + 1;
            const start = DT.d2t(player.currentTime);
            const end = DT.d2t(player.currentTime + 1.0);
            addSub(
                index,
                newSub({
                    start,
                    end,
                    text: t('SUB_TEXT'),
                }),
            );
        }
    }, [addSub, findIndex, newSub, player, subtitle]);

    return (
        <Style className={`tool ${toolOpen ? 'tool-open' : ''}`}>
            <div className={`tool-button`}>
                <div
                    className="icon"
                    onClick={() => {
                        // console.log('hamburger clicked');
                        setToolOpen(!toolOpen);
                    }}
                >
                    <HamburgerMenu
                        isOpen={toolOpen}
                        width={22}
                        height={18}
                        strokeWidth={2}
                        rotate={0}
                        color={'#fff'}
                        borderRadius={0}
                        animationDuration={0.5}
                    />
                </div>
            </div>

            <div className="top">
                <div className="youtube-link ">
                    <p>
                        <b>Fetch Video</b>
                    </p>
                    {/* <div className="btn" onClick={onYouTubeChange}>
                        <Translate value="Fetch Video" />
                    </div> */}
                    <div className="btn" onClick={() => handleFetchVideo()}>
                        <Translate value="Fetch Video" />
                    </div>
                </div>
                <div className="operate">
                    <div
                        className="btn"
                        onClick={() => {
                            if (window.confirm(t('CLEAR_TIP')) === true) {
                                // localStorage.setItem('videoSrc', '/sample.mp4');
                                localStorage.setItem('videoSrc', null);
                                localStorage.setItem('isVideoPresent', false);
                                localStorage.setItem('sentence', '');
                                localStorage.setItem('lang', 'en');
                                clearSubs();
                                clearSubsEnglish();
                                window.location.reload();
                            }
                        }}
                    >
                        <Translate value="CLEAR" />
                    </div>
                    <div className="btn" onClick={undoSubs}>
                        <Translate value="UNDO" />
                    </div>
                </div>
                <div className="operate">
                    <div className="btn" onClick={clearSubsHandler}>
                        <Translate value="Clear Subtitles" />
                    </div>
                    <div className="btn" onClick={createTextboxHandler}>
                        <Translate value="Create Textbox" />
                    </div>
                </div>
                <div className="operate" style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ paddingLeft: '10px', marginTop: '-0.5px', fontSize: '18px' }}>
                        <b>Playback Speed</b>
                    </p>
                    <select
                        // onFocus={handlePlaybackOpen}
                        // onBlur={handlePlaybackClose}
                        onClick={handleClick}
                        onChange={handlePlaybackSelect}
                        style={{ height: '50px', fontSize: '20px', textAlign: 'center' }}
                        disabled={!videoSrc}
                    >
                        <option value="0.25" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 0.25}>
                            0.25x
                        </option>
                        <option value="0.5" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 0.5}>
                            0.5x
                        </option>
                        <option value="0.75" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 0.75}>
                            0.75x
                        </option>
                        <option value="1" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 1.0}>
                            1x
                        </option>
                        <option value="1.25" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 1.25}>
                            1.25x
                        </option>
                        <option value="1.5" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 1.5}>
                            1.5x
                        </option>
                        <option value="1.75" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 1.75}>
                            1.75x
                        </option>
                        <option value="2" selected={parseFloat(localStorage.getItem('playbackSpeed') || 1) === 2.0}>
                            2x
                        </option>
                    </select>
                </div>
                <div className="operate">
                    <div className="btn" onClick={handleInvalid}>
                        <Translate value="Rerecord Video" />
                    </div>
                    <div className="btn" onClick={() => localStorage.removeItem('karya-token')}>
                        <Translate value="Clear Token" />
                    </div>
                </div>

                <div className={`secondary-options`}>
                    <p style={{ paddingLeft: '10px', marginTop: '-0.5px', fontSize: '18px' }}>
                        <b>Export Subtitles</b>
                    </p>
                    <div className="export" style={{ marginTop: '-20px' }}>
                        <div
                            className="btn"
                            onClick={() => {
                                // downloadSub('ass');
                                setIsOpen(true);
                            }}
                        >
                            <Translate value="PREVIEW" />
                        </div>
                    </div>
                </div>
                {/* <div className="renew-token">
                    <p style={{ marginTop: '-0.5px', fontSize: '18px' }}>
                        <b>Renew ID - TOKEN</b>
                    </p>
                   
                    <input
                        type="password"
                        placeholder="Enter Token Here"
                        style={{ marginTop: '-10px', marginBottom: '10px' }}
                        className="renew-token-input"
                        value={oldToken}
                        onChange={(e) => setOldToken(e.target.value)}
                    />
                    <div className="btn" onClick={handleRenewToken}>
                        <Translate value="RENEW" />
                    </div>
                </div>

                <div className="renew-token">
                    <p style={{ marginTop: '-0.5px', fontSize: '18px' }}>
                        <b>UPDATE ID - TOKEN</b>
                    </p>
                    
                    <input
                        type="password"
                        placeholder="Enter Token Here"
                        style={{ marginTop: '-10px', marginBottom: '10px' }}
                        className="renew-token-input"
                        value={tokenToUpdate}
                        onChange={(e) => setTokenToUpdate(e.target.value)}
                    />
                    <div className="btn" onClick={handleUpdateToken}>
                        <Translate value="UPDATE" />
                    </div>
                </div> */}

                {/* <div className="">
                    <BottomLinks />
                </div> */}

                <div className="hotkey">
                    <span>
                        <Translate value="HOTKEY_01" />
                    </span>
                    <span>
                        <Translate value="HOTKEY_02" />
                    </span>
                </div>
            </div>
        </Style>
    );
}
