import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import DT from 'duration-time-conversion';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import Tool from './components/Tool';
import Subtitles from './components/Subtitles';
import Player from './components/Player';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ProgressBar from './components/ProgressBar';
import { getKeyCode } from './utils';
import Sub from './libs/Sub';
import Modal from './components/Modal';
import Links from './components/Links';
import './utils/Modal.css';
import { AiOutlineClose } from 'react-icons/ai';
import ArtplayerReact from 'artplayer/examples/react/Artplayer';
import { sub2srt } from './libs/readSub';
import * as zlib from 'browserify-zlib';
import untar from 'js-untar';
import { Buffer } from 'buffer';
import { t } from 'react-i18nify';
import FFmpeg from '@ffmpeg/ffmpeg';
import TokenModal from './components/TokenModal';
import { transform, getFonts } from 'convert-unicode-fonts';

const Style = styled.div`
    height: 100%;
    width: 100%;

    .main {
        display: flex;
        height: calc(100% - 200px);

        .player {
            flex: 1;
        }

        .subtitles {
            width: 250px;
        }

        .tool {
            width: 300px;
        }
    }

    .footer {
        height: 180px;
    }
`;

export default function App({ defaultLang }) {
    const subtitleHistory = useRef([]);
    const notificationSystem = useRef(null);
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState('');
    const [processing, setProcessing] = useState(0);
    const [language, setLanguage] = useState(defaultLang);
    const [subtitle, setSubtitleOriginal] = useState([]);
    const [subtitleEnglish, setSubtitleEnglish] = useState(null);
    const [waveform, setWaveform] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [clearedSubs, setClearedSubs] = useState(false);
    const [subsBeforeClear, setSubsBeforeClear] = useState([]);
    const [configuration, setConfiguration] = useState('');
    const [enableConfiguration, setEnableConfiguration] = useState(false);
    const [isSetVideo, setIsSetVideo] = useState(false);
    const [isSetConfiguration, setIsSetConfiguration] = useState(false);
    const [translationApi, setTranslationApi] = useState('AI4Bharat');
    const [sentence, setSentence] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [videoFetched, setVideoFetched] = useState(false);
    const [tokenToUpdate, setTokenToUpdate] = useState('');
    const [isOpenTokenModal, setIsOpenTokenModal] = useState(false);
    const [renewedToken, setRenewedToken] = useState('');
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [playbackSelected, setPlaybackSelected] = useState(false);
    const [videoRef, setVideoRef] = useState(null);
    const fonts = getFonts();

    const newSub = useCallback((item) => new Sub(item), []);
    const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);
    const hasSubEnglish = useCallback((sub) => subtitleEnglish.indexOf(sub), [subtitleEnglish]);

    const formatSub = useCallback(
        (sub) => {
            if (Array.isArray(sub)) {
                return sub.map((item) => newSub(item));
            }
            return newSub(sub);
        },
        [newSub],
    );

    const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub]);
    const copySubsEnglish = useCallback(() => formatSub(subtitleEnglish), [subtitleEnglish, formatSub]);

    // useEffect(() => {
    //     //localStorage.setItem('lang', 'en');

    //     if (localStorage.getItem('videoSrc') === null) {
    //         localStorage.setItem('videoSrc', '/sample.mp4');
    //     }
    // }, []);

    const setSubtitle = useCallback(
        (newSubtitle, saveToHistory = true) => {
            // console.log('Here');
            if (!isEqual(newSubtitle, subtitle)) {
                if (saveToHistory) {
                    if (subtitleHistory.current.length >= 1000) {
                        subtitleHistory.current.shift();
                    }
                    subtitleHistory.current.push(formatSub(subtitle));
                }
                window.localStorage.setItem('subtitle', JSON.stringify(newSubtitle));
                setSubtitleOriginal(newSubtitle);
            }
        },
        [subtitle, setSubtitleOriginal, formatSub],
    );

    const undoSubs = useCallback(() => {
        const subs = subtitleHistory.current.pop();
        if (subs) {
            setSubtitle(subs, false);
        }
    }, [setSubtitle, subtitleHistory]);

    const clearSubs = useCallback(() => {
        setSubtitle([]);
        subtitleHistory.current.length = 0;
    }, [setSubtitle, subtitleHistory]);

    const clearSubsEnglish = useCallback(() => {
        localStorage.removeItem('subtitleEnglish');
        setSubtitleEnglish([]);
    }, [setSubtitleEnglish]);

    const checkSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const previous = subtitle[index - 1];
            return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2;
        },
        [subtitle, hasSub],
    );

    const notify = useCallback(
        (obj) => {
            // https://github.com/igorprado/react-notification-system
            const notification = notificationSystem.current;
            notification.clearNotifications();
            notification.addNotification({
                position: 'tc',
                dismissible: 'none',
                autoDismiss: 2,
                message: obj.message,
                level: obj.level,
            });
        },
        [notificationSystem],
    );

    const removeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            subs.splice(index, 1);
            setSubtitle(subs);
            if (subtitleEnglish) {
                // console.log('here');
                const subsEnglish = copySubsEnglish();
                subsEnglish.splice(index, 1);
                setSubtitleEnglish(subsEnglish);
                localStorage.setItem('subtitleEnglish', JSON.stringify(subsEnglish));
            }
        },
        [hasSub, copySubs, setSubtitle, copySubsEnglish, subtitleEnglish],
    );

    const addSub = useCallback(
        (index, sub) => {
            const subs = copySubs();
            subs.splice(index, 0, formatSub(sub));
            setSubtitle(subs);
            if (subtitleEnglish) {
                const subsEnglish = copySubsEnglish();
                subsEnglish.splice(index, 0, formatSub(sub));
                setSubtitleEnglish(subs);
                localStorage.setItem('subtitleEnglish', JSON.stringify(subsEnglish));
            }
        },
        [copySubs, setSubtitle, formatSub, copySubsEnglish, subtitleEnglish],
    );

    const updateSub = useCallback(
        (sub, obj) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const subClone = formatSub(sub);
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                setSubtitle(subs);
            }
        },
        [hasSub, copySubs, setSubtitle, formatSub],
    );
    const updateSubEnglish = useCallback(
        (sub, obj) => {
            const index = hasSubEnglish(sub);
            if (index < 0) return;
            const subs = copySubsEnglish();
            const subClone = formatSub(sub);
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                setSubtitleEnglish(subs);
            }
        },
        [hasSubEnglish, copySubsEnglish, setSubtitleEnglish, formatSub],
    );

    const updateSubTranslate = useCallback(
        (sub, obj, index) => {
            const subs = copySubs();
            const subClone = formatSub(sub);
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                setSubtitle(subs);
            }
        },
        [copySubs, setSubtitle, formatSub],
    );

    const mergeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const next = subs[index + 1];
            if (!next) return;
            const merge = newSub({
                start: sub.start,
                end: next.end,
                text: sub.text.trim() + '\n' + next.text.trim(),
            });
            subs[index] = merge;
            subs.splice(index + 1, 1);
            setSubtitle(subs);
            if (subtitleEnglish) {
                const subsEnglish = copySubsEnglish();
                const nextEnglish = subsEnglish[index + 1];
                if (!nextEnglish) return;
                const mergeEnglish = newSub({
                    start: sub.start,
                    end: nextEnglish.end,
                    text: subsEnglish[index].text.trim() + '\n' + nextEnglish.text.trim(),
                });
                subsEnglish[index] = mergeEnglish;
                subsEnglish.splice(index + 1, 1);
                setSubtitleEnglish(subsEnglish);
                localStorage.setItem('subtitleEnglish', JSON.stringify(subsEnglish));
            }
        },
        [hasSub, copySubs, setSubtitle, newSub, copySubsEnglish, subtitleEnglish],
    );

    const splitSub = useCallback(
        (sub, start) => {
            const index = hasSub(sub);
            if (index < 0 || !sub.text || !start) return;
            const subs = copySubs();
            const text1 = sub.text.slice(0, start).trim();
            const text2 = sub.text.slice(start).trim();
            if (!text1 || !text2) return;
            const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3);
            if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
            subs.splice(index, 1);
            const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
            subs.splice(
                index,
                0,
                newSub({
                    start: sub.start,
                    end: middleTime,
                    text: text1,
                }),
            );
            subs.splice(
                index + 1,
                0,
                newSub({
                    start: middleTime,
                    end: sub.end,
                    text: text2,
                }),
            );
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const onKeyDown = useCallback(
        (event) => {
            const keyCode = getKeyCode(event);
            switch (keyCode) {
                case 32:
                    event.preventDefault();
                    if (player) {
                        if (playing) {
                            player.pause();
                        } else {
                            player.play();
                        }
                    }
                    break;
                case 90:
                    event.preventDefault();
                    if (event.metaKey) {
                        undoSubs();
                    }
                    break;
                default:
                    break;
            }
        },
        [player, playing, undoSubs],
    );

    const wordByWord = () => {
        let sentence = '';
        let colouredSentence = '';
        return subtitle
            .map((item, index) => {
                if (
                    subtitle[index - 1] &&
                    subtitle[index - 1].text &&
                    subtitle[index - 1].text.length === 1 &&
                    item.text.length !== 1
                ) {
                    // console.log(subtitle[index - 1]);
                    sentence = sentence + ' ';
                }
                colouredSentence = sentence + `<u><b>${item.text}</b></u>`;
                sentence += item.text;
                if (item.text.length !== 1) {
                    sentence += ' ';
                }
                return `${index + 1}\n${item.start.replace('.', ',')} --> ${item.end.replace(
                    '.',
                    ',',
                )}\n${colouredSentence}`;
            })
            .join('\n\n');
    };
    const wordByWordBold = () => {
        let sentence = '';
        let colouredSentence = '';
        return subtitle
            .map((item, index) => {
                if (
                    subtitle[index - 1] &&
                    subtitle[index - 1].text &&
                    subtitle[index - 1].text.length === 1 &&
                    item.text.length !== 1
                ) {
                    // console.log(subtitle[index - 1]);
                    sentence = sentence + ' ';
                }
                colouredSentence = sentence + ` <${transform(item.text, fonts['boldItalic'])}>`;
                sentence += item.text.toUpperCase();
                if (item.text.length !== 1) {
                    sentence += ' ';
                }
                return `${index + 1}\n${item.start.replace('.', ',')} --> ${item.end.replace(
                    '.',
                    ',',
                )}\n${colouredSentence}`;
            })
            .join('\n\n');
    };

    const downloadSub = useCallback(
        (type) => {
            let wordByWordSubtitle = wordByWord();
            let text = sub2srt(subtitle);

            // const url = URL.createObjectURL(new Blob([wordByWordSubtitle]));
            // setSubtitle(url);
            // localStorage.setItem('subtitleUrl', url);

            // console.log(wordByWordSubtitle);

            const body = JSON.parse(localStorage.getItem('assignments'));
            body.status = 'COMPLETED';
            body.completed_at = new Date().toISOString();
            body.output = {
                data: {
                    format: 'srt',
                    transcript: wordByWordSubtitle,
                    originalTranscript: text,
                    valid: true,
                },
            };
            // console.log(body);
            fetch('https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignments', {
                method: 'PUT',
                headers: {
                    'karya-id-token': localStorage.getItem('karya-token'),
                    'Content-type': 'application/json',
                },
                body: JSON.stringify([body]),
            })
                .then((resp) => {
                    // console.log(resp);
                    clearSubs();
                    setSentence(null);
                    localStorage.removeItem('sentence');
                    return resp.text();
                })
                .then((resp) => {
                    // console.log(resp);
                    handleFetchVideo();
                })
                .catch((err) => {
                    console.log(err);
                });
            // console.log(wordByWordSubtitle, text);
        },
        // eslint-disable-next-line
        [subtitle],
    );

    const decodeAudioData = useCallback(
        async (file) => {
            try {
                const { createFFmpeg, fetchFile } = FFmpeg;
                const ffmpeg = createFFmpeg({ log: true });
                ffmpeg.setProgress(({ ratio }) => setProcessing(ratio * 100));
                setLoading(t('LOADING_FFMPEG'));
                await ffmpeg.load();
                ffmpeg.FS('writeFile', file.name, await fetchFile(file));
                setLoading('');
                notify({
                    message: t('DECODE_START'),
                    level: 'info',
                });
                const output = `${Date.now()}.mp3`;
                await ffmpeg.run('-i', file.name, '-ac', '1', '-ar', '8000', output);
                const uint8 = ffmpeg.FS('readFile', output);
                // download(URL.createObjectURL(new Blob([uint8])), `${output}`);
                await waveform.decoder.decodeAudioData(uint8);
                waveform.drawer.update();
                setProcessing(0);
                ffmpeg.setProgress(() => null);
                notify({
                    message: t('DECODE_SUCCESS'),
                    level: 'success',
                });
            } catch (error) {
                setLoading('');
                setProcessing(0);
                // notify({
                //     message: t('DECODE_ERROR'),
                //     level: 'error',
                // });
            }
        },
        [waveform, notify, setProcessing, setLoading],
    );

    const handleFetchVideo = async () => {
        // console.log('Clicked');

        setLoading(t('Fetching Video'));

        if (localStorage.getItem('karya-token') !== null) {
            const url = new URL('https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignments');

            url.searchParams.append('type', 'new');
            url.searchParams.append('from', '1970-01-01T00:00:00Z');

            // console.log(url.href);

            try {
                const response = await fetch(url.href, {
                    headers: {
                        'karya-id-token': localStorage.getItem('karya-token'),
                    },
                });

                const data = await response.json();

                const submitObj = data.assignments[0];

                localStorage.setItem('assignments', JSON.stringify(submitObj));

                const responseObj = {
                    id: data.assignments[0].id,
                    sentence: data.microtasks[0].input.data.sentence,
                    videoFile: data.microtasks[0].input.files.recording,
                    task_id: data.microtasks[0].task_id,
                };

                localStorage.setItem('fetchVideoObject', JSON.stringify(responseObj));
                localStorage.setItem('sentence', responseObj.sentence);
                setSentence(localStorage.getItem('sentence'));

                fetch(`https://ai4bkaryabox001.eastus.cloudapp.azure.com/assignment/${responseObj.id}/input_file`, {
                    headers: {
                        'karya-id-token': localStorage.getItem('karya-token'),
                    },
                })
                    .then((resp) => {
                        return resp.blob();
                    })
                    .then((response) => {
                        response.arrayBuffer().then((compressed) => {
                            const uncompressed = zlib.gunzipSync(Buffer.from(compressed));
                            // console.log(uncompressed);
                            untar(uncompressed.buffer).then((files) => {
                                const file = files[0].buffer;
                                // console.log(file);
                                setVideoFile(file);
                                decodeAudioData(file);
                                const url = URL.createObjectURL(new Blob([file]));
                                localStorage.setItem('videoSrc', url);
                                setVideoSrc(url);
                                setVideoFetched(true);
                                setLoading('');
                            });
                        });
                    });

                console.log(responseObj);
            } catch (error) {
                // localStorage.removeItem('karya-token');
                setLoading('');
                alert(`An Error Occured : 
                ${error}
                `);
            }
        } else {
            const token = prompt('Please Enter a Token to Access Video');

            if (token === null) return alert('No token submitted');

            localStorage.setItem('karya-token', token);

            handleFetchVideo();
        }
    };

    function findIndex(subs, startTime) {
        return subs.findIndex((item, index) => {
            return (
                (startTime >= item.endTime && !subs[index + 1]) ||
                (item.startTime <= startTime && item.endTime > startTime) ||
                (startTime >= item.endTime && subs[index + 1] && startTime < subs[index + 1].startTime)
            );
        });
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    useEffect(() => {
        if (waveform) {
            waveform.decoder.destroy();
            waveform.drawer.update();
            // waveform.seek(0);
            player.currentTime = 0;
            clearSubs();
            setSubtitle([
                newSub({
                    start: '00:00:00.000',
                    end: '00:00:01.000',
                    text: t(``),
                }),
            ]);
            player.src = localStorage.getItem('videoSrc');
        }
        // eslint-disable-next-line
    }, [waveform, videoSrc]);

    useMemo(() => {
        const currentIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
        setCurrentIndex(currentIndex);
    }, [currentTime, subtitle]);

    // useEffect(() => {
    //     // console.log(subtitle);
    //     // console.log(translationApi);
    //     const localSubtitleString = window.localStorage.getItem('subtitle');
    //     const localSubtitleEnglish = window.localStorage.getItem('subtitleEnglish');
    //     const fetchSubtitle = () =>
    //         fetch('/sample.json')
    //             .then((res) => res.json())
    //             .then((res) => {
    //                 setSubtitleOriginal(res.map((item) => new Sub(item)));
    //             });

    //     if (localSubtitleString) {
    //         try {
    //             const localSubtitle = JSON.parse(localSubtitleString);
    //             console.log(localSubtitle);
    //             if (localSubtitle.length) {
    //                 setSubtitleOriginal(localSubtitle.map((item) => new Sub(item)));
    //             } else {
    //                 fetchSubtitle();
    //             }
    //         } catch (error) {
    //             fetchSubtitle();
    //         }
    //     } else {
    //         fetchSubtitle();
    //     }
    //     if (localSubtitleEnglish) {
    //         try {
    //             const localSubtitle = JSON.parse(localSubtitleEnglish);
    //             if (localSubtitle.length) {
    //                 setSubtitleEnglish(localSubtitle.map((item) => new Sub(item)));
    //             } else {
    //                 setSubtitleEnglish([]);
    //             }
    //         } catch (error) {
    //             setSubtitleEnglish([]);
    //         }
    //     } else {
    //         // fetchSubtitle();
    //     }
    // }, [setSubtitleOriginal, setSubtitleEnglish]);

    useEffect(() => {
        if (!localStorage.getItem('sentence')) {
            // console.log('here');
            setSentence(null);
        } else {
            setSentence(localStorage.getItem('sentence'));
        }
    }, []);

    // useEffect(() => {
    //     if (localStorage.getItem('karya-token') === null) {
    //         const token = prompt('Please Enter Token to Access Video');

    //         localStorage.setItem('karya-token', token);
    //     }
    // });

    const props = {
        player,
        setPlayer,
        subtitle,
        setSubtitle,
        waveform,
        setWaveform,
        currentTime,
        setCurrentTime,
        currentIndex,
        setCurrentIndex,
        playing,
        setPlaying,
        language,
        setLanguage,
        loading,
        setLoading,
        setProcessing,
        subtitleHistory,
        subtitleEnglish,
        setSubtitleEnglish,
        notify,
        newSub,
        hasSub,
        checkSub,
        removeSub,
        addSub,
        undoSubs,
        clearSubs,
        updateSub,
        formatSub,
        mergeSub,
        splitSub,
        clearSubsEnglish,
        updateSubEnglish,
        setSubtitleOriginal,
        clearedSubs,
        setClearedSubs,
        subsBeforeClear,
        setSubsBeforeClear,
        configuration,
        setConfiguration,
        updateSubTranslate,
        enableConfiguration,
        setEnableConfiguration,
        isSetVideo,
        setIsSetVideo,
        isSetConfiguration,
        setIsSetConfiguration,
        translationApi,
        setTranslationApi,
        sentence,
        setSentence,
        videoSrc,
        setVideoSrc,
        isOpen,
        setIsOpen,
        videoFile,
        setVideoFile,
        downloadSub,
        handleFetchVideo,
        // videoFetched,
        // setVideoFetched,
        tokenToUpdate,
        setTokenToUpdate,
        isOpenTokenModal,
        setIsOpenTokenModal,
        renewedToken,
        setRenewedToken,
        playbackSpeed,
        setPlaybackSpeed,
        playbackSelected,
        setPlaybackSelected,
        setVideoRef,
        videoRef,
        findIndex,
    };

    const style = {
        NotificationItem: {
            DefaultStyle: {
                width: '500px',
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translateX(-70%)',
                marginTop: '10px',
            },
        },
    };

    return (
        <Style>
            <div className="main">
                <Links />
                <Player {...props} />
                <Subtitles
                    currentIndex={props.currentIndex}
                    subtitle={props.subtitle}
                    checkSub={props.checkSub}
                    player={props.player}
                    updateSub={props.updateSub}
                    language={props.language}
                    setLanguage={props.setLanguage}
                    setLoading={props.setLoading}
                    subtitleEnglish={props.subtitleEnglish}
                    formatSub={props.formatSub}
                    setSubtitle={props.setSubtitle}
                    notify={props.notify}
                    isPrimary={true}
                    clearedSubs={props.clearedSubs}
                    setClearedSubs={props.setClearedSubs}
                    setSubtitleOriginal={props.setSubtitleOriginal}
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                    translationApi={props.translationApi}
                />

                <Tool {...props} />
                <Modal isOpen={isOpen}>
                    <button onClick={() => props.setIsOpen(false)} className="close-btn">
                        <AiOutlineClose style={{ color: '#fff' }} />
                    </button>
                    {videoSrc && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                        >
                            <h1 style={{ textAlign: 'center', margin: '0' }}>Video Preview</h1>
                            <ArtplayerReact
                                option={{
                                    url: videoSrc || 'https://artplayer.org/assets/sample/video.mp4',
                                    autoplay: true,
                                    loop: true,
                                    subtitle: {
                                        url: URL.createObjectURL(new Blob([wordByWordBold()])),
                                        type: 'srt',
                                        style: {
                                            color: 'white',
                                        },
                                        encoding: 'utf-8',
                                        bilingual: true,
                                    },
                                }}
                                style={{
                                    width: '500px',
                                    height: '500px',
                                }}
                                getInstance={(art) => console.log(art)}
                            />
                            <button
                                onClick={() => {
                                    downloadSub('ass');
                                    props.setIsOpen(false);
                                }}
                                className="export-btn"
                            >
                                EXPORT & FETCH NEW ASSIGNMENT
                            </button>
                        </div>
                    )}
                </Modal>
                <TokenModal isOpenTokenModal={isOpenTokenModal}>
                    <button onClick={() => props.setIsOpenTokenModal(false)} className="close-btn">
                        <AiOutlineClose style={{ color: '#fff' }} />
                    </button>
                    {renewedToken !== '' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '80%',
                                flexWrap: 'wrap',
                            }}
                        >
                            <h1 style={{ margin: '0', padding: '0' }}>New Token </h1>
                            <p style={{ width: '100%', wordWrap: 'break-word', fontSize: '24px' }}>{renewedToken}</p>
                        </div>
                    )}
                </TokenModal>
                <NotificationSystem ref={notificationSystem} allowHTML={true} style={style} />
            </div>

            {videoFetched && <Footer {...props} />}
            {loading ? <Loading loading={loading} /> : null}
            {processing > 0 && processing < 100 ? <ProgressBar processing={processing} /> : null}
            {/* <NotificationSystem ref={notificationSystem} allowHTML={true} style={style} /> */}

            {/* <BottomLinks /> */}
        </Style>
    );
}
